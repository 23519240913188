/* Card Flip Animation Styles */
.card {
  position: relative;
  width: 180px;
  min-width: 180px;
  height: 180px;
  border-radius: 1.5rem;
  background: linear-gradient(135deg, #7C3AED 0%, #5B21B6 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1.5rem;
  z-index: 1;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  box-shadow: 0 10px 30px -5px rgba(124, 58, 237, 0.2);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(124, 58, 237, 0.1);
}

.card:hover {
  background: linear-gradient(135deg, #8B5CF6 0%, #6D28D9 100%);
  transform: translateY(-8px) scale(1.02);
  box-shadow: 0 20px 40px -5px rgba(124, 58, 237, 0.3);
}

.card svg {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 0.5rem;
  filter: drop-shadow(0 0 12px rgba(255, 255, 255, 0.4));
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.card:hover svg {
  transform: scale(1.15) rotate(5deg);
  filter: drop-shadow(0 0 16px rgba(255, 255, 255, 0.5));
}

.card h3 {
  color: white;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
  font-family: 'Clash Display', sans-serif;
}

.card p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
  text-align: center;
  margin: 0;
  line-height: 1.4;
}

.cardIcon {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.cardFront .cardIcon {
  color: #1a1a1a;
}

.cardFront .cardIcon svg {
  color: #1a1a1a;
  filter: none;
}

.cardFront:hover .cardIcon {
  transform: scale(1.1);
}

.cardBack .cardIcon {
  color: rgba(255, 255, 255, 0.9);
}

.cardFront:hover .cardIcon {
  transform: scale(1.1);
}

.cardBack:hover .cardIcon {
  transform: scale(1.1);
}

.cardTitle {
  font-size: 1.125rem;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  font-family: 'Clash Display', sans-serif;
  letter-spacing: 0.5px;
  max-width: 140px;
  line-height: 1.4;
}

.cardsGrid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  place-items: center;
}

.connectingLinePath {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}

.connectingLine {
  fill: none;
  stroke: url(#lineGradient);
  stroke-width: 2;
  stroke-dasharray: 5;
  filter: drop-shadow(0 0 2px rgba(124, 58, 237, 0.3));
}

.connectingDot {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #7C3AED;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0 0 4px rgba(124, 58, 237, 0.5));
  z-index: 1;
}

@media (max-width: 1024px) {
  .connectingLinePath, .connectingDot {
    display: none;
  }
  
  .cardsGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    padding: 0 1rem;
  }
}

@media (max-width: 640px) {
  .cardsGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 0 0.5rem;
  }
  
  .card {
    width: 100%;
    min-width: unset;
    height: 160px;
  }

  .card h3 {
    font-size: 1.1rem;
  }

  .card p {
    font-size: 0.8rem;
  }

  .cardIcon {
    font-size: 1.75rem;
    width: 3.5rem;
    height: 3.5rem;
  }

  .flipToSeeText {
    display: none;
  }
}

/* Section Styles */
.sectionWrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem 0;
  position: relative;
  overflow: hidden;
  perspective: 1000px;
}

.sectionWrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(124, 58, 237, 0.05),
    rgba(147, 51, 234, 0.1),
    rgba(124, 58, 237, 0.08),
    rgba(147, 51, 234, 0.15)
  );
  background-size: 400% 400%;
  animation: gradientAnimation 20s ease infinite;
  z-index: -1;
  backdrop-filter: blur(100px);
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.sectionContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: center;
}

.sectionTitle {
  font-family: 'Clash Display', sans-serif;
  font-size: 3.5rem;
  font-weight: 600;
  text-align: center;
  color: #1a1a1a;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #1a1a1a 0%, #333333 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.2;
}

.sectionSubtitleWrapper {
  max-width: 800px;
  margin: 0 auto;
}

.sectionSubtitle {
  font-size: 1.5rem;
  color: #7C3AED;
  line-height: 1.5;
  margin: 0;
  font-weight: 500;
}

@media (max-width: 768px) {
  .sectionTitle {
    font-size: 2.5rem;
  }
  
  .sectionSubtitle {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .sectionTitle {
    font-size: 2rem;
  }
  
  .sectionSubtitle {
    font-size: 1.125rem;
  }
  
  .sectionContent {
    padding: 0 1rem;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .sectionTitle {
    font-size: 2.5rem;
  }
}

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 2rem;
}

/* Scroll Animation Styles */
.fadeInUp {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fadeInUp.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Problem Solution Section Styles */
.problemSolutionSection {
  padding: 6rem 2rem;
  background: linear-gradient(180deg, rgba(124, 58, 237, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%);
  backdrop-filter: blur(8px);
}

.sectionContent {
  max-width: 1200px;
  margin: 0 auto;
}

.sectionLayout {
  display: flex;
  align-items: center;
  gap: 4rem;
  max-width: 1400px;
  margin: 0 auto;
}

.titleSection {
  flex: 1;
  max-width: 500px;
}

.sectionTitle {
  text-align: left;
  font-size: 3.5rem;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 1.5rem;
  font-family: 'Clash Display', sans-serif;
  line-height: 1.2;
  margin: 0;
}

.highlight {
  background: linear-gradient(135deg, #7C3AED 0%, #5B21B6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: #7C3AED; /* Fallback color */
  font-weight: 700;
  display: inline-block;
}

.typewriterContainer {
  display: inline-block;
  font-family: 'Clash Display', sans-serif;
}

.typewriterContainer span {
  display: inline-block;
  white-space: pre;
  opacity: 0;
}

.problemSolutionGrid {
  flex: 1.5;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  perspective: 1000px;
}

.problemSolutionCard {
  height: 350px;
  perspective: 1000px;
  cursor: pointer;
}

.cardLabel {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: rgba(124, 58, 237, 0.1);
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #7C3AED;
}

.cardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transform-style: preserve-3d;
  border-radius: 1rem;
  box-shadow: 0 10px 30px -5px rgba(124, 58, 237, 0.2);
  border: 1px solid rgba(124, 58, 237, 0.1);
}

.cardFront,
.cardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  padding: 3rem 2rem 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
}

.cardFront {
  background: linear-gradient(135deg, #E9D5FF 0%, #DDD6FE 100%);
  color: #1a1a1a;
}

.cardFront h3 {
  color: #1a1a1a;
  font-weight: 600;
  margin-bottom: 1rem;
}

.cardFront p {
  color: #374151;
  opacity: 0.9;
}

.cardBack {
  background: linear-gradient(135deg, #7C3AED 0%, #5B21B6 100%);
  color: white;
  transform: rotateY(180deg);
}

.cardFront h3,
.cardBack h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  font-family: 'Clash Display', sans-serif;
  text-align: center;
}

.cardFront p,
.cardBack p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  text-align: center;
  opacity: 0.9;
}

.flipHint {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
}

.flipIcon {
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.9);
}

.arrowIcon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  transition: all 0.3s ease;
}

.arrowIcon:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.1);
}

@media (max-width: 1280px) {
  .sectionLayout {
    flex-direction: column;
    padding: 0 2rem;
  }

  .titleSection {
    max-width: 100%;
    margin-bottom: 3rem;
  }

  .sectionTitle {
    text-align: center;
    font-size: 3rem;
  }

  .highlight {
    font-size: 2.4rem;
  }

  .problemSolutionGrid {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .sectionTitle {
    font-size: 2.5rem;
  }

  .highlight {
    font-size: 2rem;
  }

  .problemSolutionGrid {
    grid-template-columns: 1fr;
  }

  .problemSolutionCard {
    height: 300px;
  }
}

/* Testimonial Section Styles */
.testimonialSection {
  padding: 6rem 2rem;
  background: #ffffff;
}

.testimonialGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}

@media (max-width: 1024px) {
  .testimonialGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .testimonialGrid {
    grid-template-columns: 1fr;
    max-width: 500px;
  }
}

.testimonialCard {
  background: white;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: all 0.3s ease;
}

.testimonialHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.testimonialPhoto {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #7C3AED;
}

.testimonialInfo h4 {
  font-family: 'Clash Display', sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
}

.testimonialInfo p {
  font-size: 0.875rem;
  color: #666;
  margin: 0;
}

.testimonialQuote {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 1rem;
}

.testimonialFeature {
  display: inline-block;
  background: linear-gradient(135deg, rgba(124, 58, 237, 0.1) 0%, rgba(91, 33, 182, 0.1) 100%);
  color: #7C3AED;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.testimonialHeader {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 4rem;
  padding: 0 1rem;
}

.testimonialTitle {
  font-family: 'Clash Display', sans-serif;
  font-size: 4rem;
  font-weight: 700;
  background: linear-gradient(135deg, #1a1a1a 0%, #333333 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  position: relative;
}

.testimonialSubtitle {
  font-size: 1.5rem;
  color: #7C3AED;
  line-height: 1.5;
  margin-bottom: 2rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .testimonialTitle {
    font-size: 3rem;
  }
  
  .testimonialSubtitle {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .testimonialTitle {
    font-size: 2.5rem;
  }
  
  .testimonialSubtitle {
    font-size: 1.125rem;
  }
  
  .testimonialHeader {
    margin-bottom: 3rem;
  }
}

/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 1rem;
}

.modalContent {
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  max-width: 600px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.modalClose {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #666;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.modalClose:hover {
  background: rgba(0, 0, 0, 0.1);
}

.highlightQuote {
  font-family: 'Clash Display', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  color: #7C3AED;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.4;
}

.modalHeader {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.modalHeader img {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #7C3AED;
}

.modalHeader h3 {
  font-family: 'Clash Display', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
}

.modalHeader p {
  font-size: 1rem;
  color: #666;
  margin: 0.25rem 0 0 0;
}

.modalStory {
  font-size: 1.125rem;
  line-height: 1.7;
  color: #333;
  margin-bottom: 2rem;
}

.modalImages {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.modalImages img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 0.5rem;
}

/* Section Styles */
.sectionWrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem 0;
  position: relative;
  overflow: hidden;
  perspective: 1000px;
}

.sectionWrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(124, 58, 237, 0.05),
    rgba(147, 51, 234, 0.1),
    rgba(124, 58, 237, 0.08),
    rgba(147, 51, 234, 0.15)
  );
  background-size: 400% 400%;
  animation: gradientAnimation 20s ease infinite;
  z-index: -1;
  backdrop-filter: blur(100px);
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.sectionContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: center;
}

.sectionTitle {
  font-family: 'Clash Display', sans-serif;
  font-size: 3.5rem;
  font-weight: 600;
  text-align: center;
  color: #1a1a1a;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #1a1a1a 0%, #333333 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.2;
}

.sectionSubtitleWrapper {
  max-width: 800px;
  margin: 0 auto;
}

.sectionSubtitle {
  font-size: 1.5rem;
  color: #7C3AED;
  line-height: 1.5;
  margin: 0;
  font-weight: 500;
}

@media (max-width: 768px) {
  .sectionTitle {
    font-size: 2.5rem;
  }
  
  .sectionSubtitle {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .sectionTitle {
    font-size: 2rem;
  }
  
  .sectionSubtitle {
    font-size: 1.125rem;
  }
  
  .sectionContent {
    padding: 0 1rem;
  }
}

/* Transform Section Styles */
.transformSection {
  position: relative;
  padding: 8rem 0;
  background: linear-gradient(
    135deg,
    rgba(124, 58, 237, 0.05) 0%,
    rgba(109, 40, 217, 0.1) 100%
  );
  overflow: hidden;
}

.transformSection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(124, 58, 237, 0.2),
    transparent
  );
}

.transformContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
  z-index: 1;
}

.transformHeader {
  text-align: center;
  position: relative;
  margin-bottom: 4rem;
}

.transformTitle {
  font-family: 'Clash Display', sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.4;
  gap: 0.5rem;
}

.highlightText {
  color: #7C3AED;
  font-size: 3rem;
  font-weight: 700;
  margin-top: 0.5rem;
}

.transformGlow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120%;
  height: 120%;
  background: radial-gradient(
    circle,
    rgba(124, 58, 237, 0.1) 0%,
    transparent 70%
  );
  z-index: 0;
  pointer-events: none;
}

.transformSubtitle {
  font-size: 1.5rem;
  color: #7C3AED;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.5;
  position: relative;
  z-index: 1;
}

.transformFeatures {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 4rem auto;
  max-width: 1200px;
  padding: 0 1rem;
}

.featureItem {
  background: white;
  padding: 2.5rem 2rem;
  border-radius: 1rem;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.featureItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(124, 58, 237, 0.15);
  background: linear-gradient(
    135deg,
    rgba(124, 58, 237, 0.03) 0%,
    rgba(109, 40, 217, 0.06) 100%
  );
}

.featureIcon {
  font-size: 2.5rem;
  color: #7C3AED;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}

.featureItem:hover .featureIcon {
  transform: scale(1.1);
  color: #5B21B6;
}

.featureItem h3 {
  font-family: 'Clash Display', sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.featureItem p {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
  margin: 0;
  flex-grow: 1;
}

@media (max-width: 1200px) {
  .transformFeatures {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    padding: 0 2rem;
  }
  
  .featureItem {
    padding: 2rem 1.5rem;
  }
}

@media (max-width: 1024px) {
  .transformFeatures {
    grid-template-columns: repeat(2, 1fr);
    max-width: 800px;
  }
}

@media (max-width: 640px) {
  .transformFeatures {
    grid-template-columns: 1fr;
    max-width: 400px;
    gap: 1.25rem;
    padding: 0 1rem;
  }
  
  .featureItem {
    padding: 2rem 1.5rem;
  }
}

.transformCTA {
  text-align: center;
  margin: 4rem auto 0;
  max-width: 1200px;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
}

.exploreButton {
  margin-top: 2rem;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background: #7C3AED;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.exploreButton:hover {
  background: #6D28D9;
  transform: translateY(-2px);
}

.transformButton {
  border-radius: 100px;
  background: linear-gradient(135deg, #7C3AED 0%, #5B21B6 100%);
  color: white;
  padding: 1rem 2rem;
  font-size: 1.125rem;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px -5px rgba(124, 58, 237, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.transformButton:hover {
  background: linear-gradient(135deg, #6D28D9 0%, #4C1D95 100%);
  transform: translateY(-2px);
  box-shadow: 0 20px 40px -5px rgba(124, 58, 237, 0.4);
}

.submitButton {
  border-radius: 100px;
  width: 80%;
  padding: 1rem 2rem;
  background: linear-gradient(135deg, #7C3AED 0%, #5B21B6 100%);
  color: white;
  font-weight: 500;
  font-size: 1.125rem;
  transition: all 0.3s ease;
  border: none;
  margin: 2rem auto 0;
  display: block;
  cursor: pointer;
  box-shadow: 0 8px 20px -4px rgba(124, 58, 237, 0.3);
}

.submitButton:hover {
  background: linear-gradient(135deg, #6D28D9 0%, #4C1D95 100%);
  transform: translateY(-2px);
  box-shadow: 0 12px 30px -4px rgba(124, 58, 237, 0.4);
}

@media (max-width: 640px) {
  .transformButton {
    min-width: 200px;
    padding: 1rem 2.5rem;
    font-size: 1rem;
  }
}

@media (max-width: 1024px) {
  .transformFeatures {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .transformTitle {
    font-size: 3.5rem;
  }
}

@media (max-width: 768px) {
  .transformSection {
    padding: 6rem 0;
  }
  
  .transformTitle {
    font-size: 3rem;
  }
  
  .transformSubtitle {
    font-size: 1.25rem;
  }
}

@media (max-width: 640px) {
  .transformFeatures {
    grid-template-columns: 1fr;
  }
  
  .transformTitle {
    font-size: 2.5rem;
  }
  
  .transformContent {
    padding: 0 1rem;
  }
  
  .featureItem {
    padding: 1.5rem;
  }
}

.transformButton {
  border-radius: 100px;
  background: linear-gradient(135deg, #7C3AED 0%, #5B21B6 100%);
  color: white;
  padding: 1rem 2rem;
  font-size: 1.125rem;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px -5px rgba(124, 58, 237, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.transformButton:hover {
  background: linear-gradient(135deg, #6D28D9 0%, #4C1D95 100%);
  transform: translateY(-2px);
  box-shadow: 0 20px 40px -5px rgba(124, 58, 237, 0.4);
}

.submitButton {
  border-radius: 100px;
  width: 80%;
  padding: 1rem 2rem;
  background: linear-gradient(135deg, #7C3AED 0%, #5B21B6 100%);
  color: white;
  font-weight: 500;
  font-size: 1.125rem;
  transition: all 0.3s ease;
  border: none;
  margin: 2rem auto 0;
  display: block;
  cursor: pointer;
  box-shadow: 0 8px 20px -4px rgba(124, 58, 237, 0.3);
}

.submitButton:hover {
  background: linear-gradient(135deg, #6D28D9 0%, #4C1D95 100%);
  transform: translateY(-2px);
  box-shadow: 0 12px 30px -4px rgba(124, 58, 237, 0.4);
}

@media (max-width: 640px) {
  .transformButton {
    min-width: 200px;
    padding: 1rem 2.5rem;
    font-size: 1rem;
  }
}

@media (max-width: 1024px) {
  .transformFeatures {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .transformTitle {
    font-size: 3.5rem;
  }
}

@media (max-width: 768px) {
  .transformSection {
    padding: 6rem 0;
  }
  
  .transformTitle {
    font-size: 3rem;
  }
  
  .transformSubtitle {
    font-size: 1.25rem;
  }
}

@media (max-width: 640px) {
  .transformFeatures {
    grid-template-columns: 1fr;
  }
  
  .transformTitle {
    font-size: 2.5rem;
  }
  
  .transformContent {
    padding: 0 1rem;
  }
  
  .featureItem {
    padding: 1.5rem;
  }
}

.tab {
  padding: 0.75rem 2rem;
  border-radius: 0.75rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  border: none;
  font-size: 1rem;
}

.tabAttendee {
  background: rgba(34, 197, 94, 0.1);
  color: #16a34a;
}

.tabOrganizer {
  background: rgba(239, 68, 68, 0.1);
  color: #dc2626;
}

.tab.active {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.1);
}

.tabAttendee.active {
  background: rgba(34, 197, 94, 0.2);
}

.tabOrganizer.active {
  background: rgba(239, 68, 68, 0.2);
}

.tab::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  width: 0;
  height: 2px;
  background: currentColor;
  transform: translateX(-50%);
  transition: width 0.3s ease;
}

.tab.active::after {
  width: 80%;
}

/* Contact Section Styles */
.contactSection {
  padding: 6rem 0;
  background: linear-gradient(to bottom, #ffffff, #f8f9ff);
}

.contactContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.contactContent {
  display: flex;
  align-items: flex-start;
  gap: 4rem;
  max-width: 1200px;
  margin: 0 auto;
}

.contactText {
  flex: 0.8;
}

.contactForm {
  position: relative;
  flex: 1.2;
  background: white;
  padding: 2.5rem;
  border-radius: 1.5rem;
  box-shadow: 0 10px 30px -5px rgba(124, 58, 237, 0.15);
  border: 1px solid rgba(124, 58, 237, 0.1);
  min-width: 500px;
}

.contactText h2.sectionTitle {
  text-align: left;
  font-size: 3rem;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.contactDescription {
  font-size: 1.25rem;
  color: #4B5563;
  margin: 1.5rem 0;
  line-height: 1.6;
  text-align: left;
  max-width: 90%;
}

.socialProof {
  display: flex;
  justify-content: flex-start;
  gap: 2.5rem;
  margin-top: 2.5rem;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 1rem 0;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.socialProof::-webkit-scrollbar {
  display: none;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  min-width: auto;
  white-space: nowrap;
}

.statNumber {
  font-size: 2.5rem;
  font-weight: 700;
  color: #7C3AED;
  font-family: 'Clash Display', sans-serif;
  line-height: 1.2;
}

.statLabel {
  font-size: 1rem;
  color: #6B7280;
  line-height: 1.4;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .contactContent {
    flex-direction: column;
    gap: 3rem;
  }

  .contactText {
    text-align: center;
  }

  .socialProof {
    margin-top: 2rem;
    justify-content: center;
    gap: 2rem;
  }

  .stat {
    text-align: center;
    align-items: center;
  }

  .statNumber {
    font-size: 2.25rem;
  }

  .statLabel {
    font-size: 0.875rem;
  }

  .submitButton {
    width: 100%;
    margin-top: 1.5rem;
  }
}

@media (max-width: 480px) {
  .contactContainer {
    padding: 0 1rem;
  }

  .socialProof {
    gap: 1.5rem;
    margin-top: 1.5rem;
    justify-content: center;
    padding: 0.5rem 0;
  }

  .statNumber {
    font-size: 2rem;
  }

  .statLabel {
    font-size: 0.75rem;
  }

  .submitButton {
    padding: 0.875rem 1.5rem;
    font-size: 1rem;
  }
}

@media (max-width: 1024px) {
  .contactForm {
    min-width: 450px;
  }
}

@media (max-width: 768px) {
  .contactContent {
    flex-direction: column;
    gap: 3rem;
  }

  .contactForm {
    min-width: 100%;
    padding: 2rem;
  }

  .contactText {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .contactForm {
    padding: 1.5rem;
  }
}

.formGroup {
  margin-bottom: 1.5rem;
  position: relative;
}

.requiredIndicator {
  display: none;
}

.contactForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 500px;
}

.formGroup {
  width: 100%;
}

/* Center the submit button container */
.submitButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.submitButton {
  margin: 0 auto;
  min-width: 120px;
  padding: 0.75rem 2rem;
}

.formInput,
.formTextarea {
  width: 100%;
  padding: 1rem;
  border: 2px solid #E5E7EB;
  border-radius: 0.75rem;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.formInput:focus,
.formTextarea:focus {
  outline: none;
  border-color: #7C3AED;
  box-shadow: 0 0 0 3px rgba(124, 58, 237, 0.1);
}

.formTextarea {
  min-height: 120px;
  resize: vertical;
}

.submitButton {
  width: 100%;
  padding: 1rem;
  background: linear-gradient(135deg, #7C3AED 0%, #5B21B6 100%);
  color: white;
  border: none;
  border-radius: 2rem;
  font-size: 1.125rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submitButton:hover {
  background: linear-gradient(135deg, #8B5CF6 0%, #6D28D9 100%);
  transform: translateY(-2px);
}

.phoneInputGroup {
  display: flex;
  gap: 0.5rem;
  width: 100%;
}

.countryCode {
  width: 120px;
  flex-shrink: 0;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%237C3AED' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px;
  padding-right: 2.5rem;
}

.phoneNumber {
  flex: 1;
  min-width: 0;
}

.customCodeContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 120px;
  flex-shrink: 0;
}

.customCodeContainer input {
  width: 100%;
  padding-right: 2.5rem;
}

.dropdownArrow {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%237C3AED' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.8;
  transition: opacity 0.2s;
}

.dropdownArrow:hover {
  opacity: 1;
}

@media (max-width: 640px) {
  .phoneInputGroup {
    flex-direction: row;
    gap: 0.25rem;
    width: 100%;
  }

  .countryCode {
    width: 90px;
    min-width: 90px;
  }

  .phoneNumber {
    width: 0;
    min-width: 0;
  }

  .customCodeContainer {
    width: 90px;
    min-width: 90px;
  }

  .customCodeContainer input {
    width: 100%;
    padding-right: 2rem;
  }

  .dropdownArrow {
    right: 0.5rem;
    width: 14px;
    height: 14px;
  }
}

.errorContainer {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: rgba(220, 38, 38, 0.1);
  border: 1px solid rgba(220, 38, 38, 0.2);
}

.errorMessage {
  color: rgb(220, 38, 38);
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.errorMessage:last-child {
  margin-bottom: 0;
}

.formSuccess {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin: 1rem 0;
  background: rgba(124, 58, 237, 0.1);
  border-radius: 0.5rem;
  text-align: center;
}

.checkmark {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #7C3AED 0%, #5B21B6 100%);
  color: white;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.successMessage {
  color: #7C3AED;
  font-weight: 500;
  margin-top: 0.5rem;
}

/* Footer Styles */
.footer {
  padding: 1rem 0;
  background: linear-gradient(to right, #f8f9ff, #ffffff, #f8f9ff);
  border-top: 1px solid rgba(124, 58, 237, 0.1);
  margin-top: 3rem;
}

.footerContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: center;
}

.footerText {
  font-size: 0.875rem;
  color: #4B5563;
  line-height: 1.5;
}

.footerLink {
  color: #7C3AED;
  text-decoration: none;
  transition: all 0.2s ease;
}

.footerLink:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer {
    padding: 0.75rem 0;
  }
  
  .footerText {
    font-size: 0.75rem;
  }
}

/* Who Is This For? Section Styles */
.whoIsThisFor {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, rgba(124, 58, 237, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.whoIsThisForContent {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.whoIsThisForTitle {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  color: #1a1a1a;
  margin-bottom: 1rem;
  font-family: 'Clash Display', sans-serif;
}

.whoIsThisForDescription {
  text-align: center;
  max-width: 600px;
  margin: 0 auto 2rem;
  color: #4B5563;
  line-height: 1.6;
  font-size: 1.125rem;
}

.tabContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
}

.tab {
  padding: 0.75rem 2rem;
  border-radius: 0.75rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  border: none;
  font-size: 1rem;
}

.tabAttendee {
  background: rgba(34, 197, 94, 0.1);
  color: #16a34a;
}

.tabOrganizer {
  background: rgba(239, 68, 68, 0.1);
  color: #dc2626;
}

.tab.active {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.1);
}

.tabAttendee.active {
  background: rgba(34, 197, 94, 0.2);
}

.tabOrganizer.active {
  background: rgba(239, 68, 68, 0.2);
}

.tab::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  width: 0;
  height: 2px;
  background: currentColor;
  transform: translateX(-50%);
  transition: width 0.3s ease;
}

.tab.active::after {
  width: 80%;
}

.featureCardsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  width: 100%;
  padding: 1rem;
}

.featureCard {
  aspect-ratio: 1;
  background: white;
  border-radius: 1rem;
  padding: 1.5rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(124, 58, 237, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;
  cursor: pointer;
}

.featureCard:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 30px -5px rgba(124, 58, 237, 0.1);
  border-color: rgba(124, 58, 237, 0.3);
}

.featureIconWrapper {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.featureIcon {
  font-size: 2.5rem;
  color: #7C3AED;
  transition: all 0.3s ease;
}

.featureCard:hover .featureIcon {
  transform: scale(1.1) rotate(5deg);
  color: #6D28D9;
}

.featureTitle {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1a1a1a;
  font-family: 'Clash Display', sans-serif;
  margin: 0;
  line-height: 1.2;
}

.featureDescription {
  color: #4B5563;
  font-size: 0.875rem;
  line-height: 1.4;
  margin: 0;
  max-width: 200px;
}

@media (max-width: 1024px) {
  .featureCardsGrid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 768px) {
  .whoIsThisFor {
    padding: 3rem 1rem;
  }
  
  .whoIsThisForTitle {
    font-size: 2rem;
  }
  
  .tab {
    padding: 0.5rem 1.5rem;
  }
  
  .featureCardsGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  .featureCardsGrid {
    grid-template-columns: 1fr;
  }
  
  .featureCard {
    aspect-ratio: auto;
    padding: 1.25rem;
  }
}
